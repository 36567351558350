.transparentWrapper {
   min-height: 100vh;
   min-width: 100vw;
   height: 100%;
   
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  // background-image: url();
  background-color: #0e1d329c; /* Replace with your desired color */
  background-blend-mode: overlay; /* Adjust the blend mode as needed */
  background-image: url("https://storage.googleapis.com/assestsbookiebrawler/background-images/bloohunnuits_A_high-tech_sports_betting_paradise_with_a_giganti_507b250d-f6e6-45d7-af16-96e570a9eb79.PNG");
  background-position: center;
  background-repeat: no-repeat; 
  background-size: cover;
}

.header,
.footer {
  flex-shrink: 0; /* Prevents shrinking of header and footer */
}

.suggestion-container{
  min-height: 80vh !important;
}

.body {
  overflow-y: auto; /* Enables vertical scrolling */
  flex-grow: 1; /* Allows the body to take up all available space */
  padding: 10px; /* Adds some padding around the content */
  width: 100%;
}

.modalHeading{
  overflow: auto; 
}

.buttonContainer {
  width: 100%; // This ensures the container takes full width
  display: flex;
  justify-content: space-between; // This will space your buttons evenly
  position: sticky;
  bottom: 0; // This sticks the container to the bottom
}

.dark-transparent{
  background: #01183ae8 !important;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
//  min-height: 400px !important;
 // height: 80vh; /* Adjust based on your preference */
  min-height: max-content !important;
  max-height: 80vh;
  height :100%;
  background: #01183abf;
  padding: 16px;
  border-radius: 10px;
  width: 420px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}




.inputWrapper {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #aaa;
  outline: none;
}

.buttonWrapper {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: blue;
  color: white;
  cursor: pointer;
}

.buttonWrapper:hover {
  background: darkblue;
}

.logo {
  width: 13%;
  height: auto;
  margin-right: 10px !important;
}



.shine,
.chrome {
  font-size: 1.5em;
  margin: 0 auto;
  margin-bottom: .5em;
  font-weight: bold;
}

.shine,
.chrome {
  font-size: 1.5em;
  margin: 0 auto;
  margin-bottom: .5em;
  font-weight: bold;
  color: transparent;
}

.shine {
  background: linear-gradient(90deg, #e6bb1fb6, #ede500, #e6bb1fb6) repeat;
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  animation: shine 5s linear infinite;
  text-shadow: 0 0px 0px rgba(255, 255, 255, 0.5);
}

.badge {
  display: inline-block;
  padding: 8px 12px;
  margin-left: 12px;
  background-color: #97c5df;
  border-radius: 16px;
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

.rank {
  background-color: #01183a;
  color: #a6cee5;
  padding: 4px 8px;
  border-radius: 8px;
}

.total {
  background-color: #d3e8f5;
  padding: 2px 4px;
  border-radius: 4px;
}

.chrome {
  background: linear-gradient(90deg, #ffd700, #fff, #ffd700) repeat;
  background-size: 200% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  animation: shine 5s linear infinite;
  text-shadow: 0 0px 0px rgba(255, 255, 255, 0.5);
}

@keyframes shine {
  0% {
    background-position: 200%;
  }
  100% {
    background-position: -100%;
  }
}
